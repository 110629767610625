import React from 'react'

function RenderPopUp(props) {
    
  // const profile = require('../../assets/profile.png');
  const Umesh = require('../../assets/Umesh_Hade.png');
  const Niranjan = require('../../assets/Niranjan_Armal.png');
  const Nilesh  =require('../../assets/Nilesh.png');
  const Samadhan = require('../../assets/Samadhan.png');
  const Swapnil = require('../../assets/Swapnil.png');
  const Yogesh = require('../../assets/Yogesh__Hade.png');

  return (
    <div className="TeamDiv">
          <div className="innerTeamDiv">
            <div className="closeTeamModal" onClick={props.handlePop}>
              <div className="closeBtn">
                X
              </div>
            </div>
            <div className="teamCard">
              <div className="teamImage">
                <div className='profilePhotoBack'>
                  <img className='profileImg' alt="profilePhoto" src={Niranjan}/>
                </div>
              </div>
              <div className="teamDescription">
                <div className="teamName">
                  Niranjan Armal
                </div>
                <div className="teamDeveloper">
                  Proprietor
                </div>
              </div>
            </div>
            <div className="teamCard">
              <div className="teamImage">
                <div className='profilePhotoBack'>
                  <img className='profileImg' alt="profilePhoto" src={Umesh}/>
                </div>
              </div>
              <div className="teamDescription">
                <div className="teamName">
                  Umesh Hade
                </div>
                <div className="teamDeveloper">
                  Partner
                </div>
              </div>
            </div>
            <div className="teamCard">
              <div className="teamImage">
                <div className='profilePhotoBack'>
                  <img className='profileImg' alt="profilePhoto" src={Yogesh}/>
                </div>
              </div>
              <div className="teamDescription">
                <div className="teamName">
                  Yogesh Hade
                </div>
                <div className="teamDeveloper">
                  Wholesale Distributor and Manager
                </div>
              </div>
            </div>
            <div className="teamCard">
            <div className="teamImage">
                <div className='profilePhotoBack'>
                  <img className='profileImg' alt="profilePhoto" src={Samadhan}/>
                </div>
              </div>
              <div className="teamDescription">
                <div className="teamName">
                  Samadhan Kupekar
                </div>
                <div className="teamDeveloper">
                  CSM and Salesperson
                </div>
              </div>
            </div>
            <div className="teamCard">
            <div className="teamImage">
                <div className='profilePhotoBack'>
                  <img className='profileImg' alt="profilePhoto" src={Swapnil}/>
                </div>
              </div>
              <div className="teamDescription">
                <div className="teamName">
                  Swapnil Rahate
                </div>
                <div className="teamDeveloper">
                  CSM and Salesperson
                </div>
              </div>
            </div>
            <div className="teamCard">
            <div className="teamImage">
                <div className='profilePhotoBack'>
                  <img className='profileImg' alt="profilePhoto" src={Nilesh}/>
                </div>
              </div>
              <div className="teamDescription">
                <div className="teamName">
                  Nilesh Armal
                </div>
                <div className="teamDeveloper">
                  <div>
                    Web Developer
                  </div>
                  <div className='contactDev'>
                    <a target="_blank" rel="noreferrer" href="https://webdev.siddhantirrigation.com">Contact Me</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  )
}

export default RenderPopUp