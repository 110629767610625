import React,{useState} from "react";
import "../CSS/ContactUs.css";
import { IoMdArrowDropright } from "react-icons/io";
import RenderPopUp from "./subComponents/RenderPopUp";


// I know this component big and can be divided into subCompoents...

function ContactUs() {
  const [showPopUp,setShowPopUp]=useState(false);
  const [addressClass,setAddressClass]=useState("ContactItem")
  const [address,setAddress] =useState("Siddhant Irrigation Center, Deulgaon Mahi,TQ- Deulgaon Raja, Dist-Buldhana, 443206");
  const top_leaf = require('../assets/top_leaf.png');

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  //address should be coppied to clipboard and message should be shown...........
  const handleAddresClick=async()=>{
    navigator.clipboard.writeText(address);
    setAddressClass("ContactItem addressItem");
    setAddress("Address copied to clipboard.");
    
    await delay(1000);

    setAddress("Siddhant Irrigation Center, Deulgaon Mahi,TQ- Deulgaon Raja, Dist-Buldhana, 443206");
    setAddressClass("ContactItem");
  }

  //handle team pop up here...........
  const handlePopState=()=>{
    if(showPopUp){
      setShowPopUp(false);
    }
    else{
      setShowPopUp(true);
    }
  }

  
  const renderPopUp=()=>{
    return(
    <>
      <RenderPopUp handlePop={handlePopState}/>
    </>
    );
  }
  return (
    <div className="Contactus">
      {
        showPopUp?renderPopUp():null
      }
      <div className="OuterLeafDiv">
        <div className='belowLeaf1'>
          <img alt="belowLeaf" src={top_leaf}/>
        </div>
        <div className='belowLeaf2'>
          <img alt="topLeaf" src={top_leaf}/>
        </div>
      </div>
      <div className="ContactCol">
        <div className="ContactItemHead">Find Us</div>
        <div
          className={addressClass}
          onClick={handleAddresClick}
        >
          <span className="animatedIcon">
            <IoMdArrowDropright />
          </span>
          {address}
        </div>
        <div className="ContactItemIcon">
          <a
            href="https://goo.gl/maps/6ZjqVU2sfrxHGG8d8"
            target="_blank"
            rel="noreferrer"
          >
            <span className="animatedIcon">
              <IoMdArrowDropright />
            </span>
            Google Map
          </a>
        </div>
        <div className="ContactItemIcon">
          <a href="https://g.co/kgs/7F2vNt" target="_blank" rel="noreferrer">
            <span className="animatedIcon">
              <IoMdArrowDropright />
            </span>
            Google
          </a>
        </div>
      </div>
      <div className="ContactCol">
        <div className="ContactItemHead">About Us</div>
        <div className="ContactItem">
          <span className="animatedIcon">
            <IoMdArrowDropright />
          </span>
          Niranjan Armal
        </div>
        <div className="ContactItem">
          <span className="animatedIcon">
            <IoMdArrowDropright />
          </span>
  
          <span onClick={handlePopState}>
            Team
          </span>
        </div>
        <div className="ContactItem socialIcons">
          <div className="ContactItemIcon">
            <a
              href="https://www.instagram.com/siddhantirrigation"
              target="_blank"
              rel="noreferrer"
            >
              <div className="contactSocial">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="20,28,206,206"
                  style={{ fill: "#000000" }}
                >
                  <g
                    fillOpacity="0"
                    fill="#000000"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="1"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="10"
                    strokeDasharray=""
                    strokeDashoffset="0"
                    fontFamily="none"
                    fontWeight="none"
                    fontSize="none"
                    textAnchor="none"
                    style={{ mixBlendMode: "normal" }}
                  >
                    <path d="M0,256v-256h256v256z" id="bgRectangle"></path>
                  </g>
                  <g
                    fill="none"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="none"
                    strokeLinecap="none"
                    strokeLinejoin="none"
                    strokeMiterlimit="10"
                    strokeDasharray=""
                    strokeDashoffset="0"
                    fontFamily="none"
                    fontWeight="none"
                    fontSize="none"
                    textAnchor="none"
                    style={{ mixBlendMode: "normal" }}
                  >
                    <g transform="scale(5.33333,5.33333)">
                      <path
                        d="M41.67,13.48c-0.4,0.26 -0.97,0.5 -1.21,0.77c-0.09,0.09 -0.14,0.19 -0.12,0.29v1.03l-0.3,1.01l-0.3,1l-0.33,1.1l-0.68,2.25l-0.66,2.22l-0.5,1.67c0,0.26 -0.01,0.52 -0.03,0.77c-0.07,0.96 -0.27,1.88 -0.59,2.74c-0.19,0.53 -0.42,1.04 -0.7,1.52c-0.1,0.19 -0.22,0.38 -0.34,0.56c-0.4,0.63 -0.88,1.21 -1.41,1.72c-0.41,0.41 -0.86,0.79 -1.35,1.11c0,0 0,0 -0.01,0c-0.08,0.07 -0.17,0.13 -0.27,0.18c-0.31,0.21 -0.64,0.39 -0.98,0.55c-0.23,0.12 -0.46,0.22 -0.7,0.31c-0.05,0.03 -0.11,0.05 -0.16,0.07c-0.57,0.27 -1.23,0.45 -1.89,0.54c-0.04,0.01 -0.07,0.01 -0.11,0.02c-0.4,0.07 -0.79,0.13 -1.19,0.16c-0.18,0.02 -0.37,0.03 -0.55,0.03l-0.71,-0.04l-3.42,-0.18c0,-0.01 -0.01,0 -0.01,0l-1.72,-0.09c-0.13,0 -0.27,0 -0.4,-0.01c-0.54,-0.02 -1.06,-0.08 -1.58,-0.19c-0.01,0 -0.01,0 -0.01,0c-0.95,-0.18 -1.86,-0.5 -2.71,-0.93c-0.47,-0.24 -0.93,-0.51 -1.36,-0.82c-0.18,-0.13 -0.35,-0.27 -0.52,-0.42c-0.48,-0.4 -0.91,-0.83 -1.31,-1.27c-0.06,-0.06 -0.11,-0.12 -0.16,-0.18c-0.06,-0.06 -0.12,-0.13 -0.17,-0.19c-0.38,-0.48 -0.7,-0.97 -0.96,-1.49c-0.24,-0.46 -0.43,-0.95 -0.58,-1.49c-0.06,-0.19 -0.11,-0.37 -0.15,-0.57c-0.01,-0.01 -0.02,-0.03 -0.02,-0.05c-0.1,-0.41 -0.19,-0.84 -0.24,-1.27c-0.06,-0.33 -0.09,-0.66 -0.09,-1c-0.02,-0.13 -0.02,-0.27 -0.02,-0.4l1.91,-2.95l1.87,-2.88l0.85,-1.31l0.77,-1.18l0.26,-0.41v-1.03c0.02,-0.23 0.03,-0.47 0.02,-0.69c-0.01,-0.7 -0.15,-1.38 -0.38,-2.03c-0.22,-0.69 -0.53,-1.34 -0.85,-1.94c-0.38,-0.69 -0.78,-1.31 -1.11,-1.87c-0.49,-0.82 -0.83,-1.49 -0.74,-1.96c0.72,-0.17 1.48,-0.26 2.25,-0.26h16c4.18,0 7.78,2.6 9.27,6.26c0.16,0.39 0.3,0.8 0.4,1.22z"
                        fill="#304ffe"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                      ></path>
                      <path
                        d="M42,16v0.27l-1.38,0.8l-0.88,0.51l-0.97,0.56l-1.94,1.13l-1.9,1.1l-1.94,1.12l-0.77,0.45c0,0.48 -0.12,0.92 -0.34,1.32c-0.31,0.58 -0.83,1.06 -1.49,1.47c-0.67,0.41 -1.49,0.74 -2.41,0.98c0,0 0,-0.01 -0.01,0c-3.56,0.92 -8.42,0.5 -10.78,-1.26c-0.66,-0.49 -1.12,-1.09 -1.32,-1.78c-0.06,-0.23 -0.09,-0.48 -0.09,-0.73v-7.19c0.01,-0.15 -0.09,-0.3 -0.27,-0.45c-0.54,-0.43 -1.81,-0.84 -3.23,-1.25c-1.11,-0.31 -2.3,-0.62 -3.3,-0.92c-0.79,-0.24 -1.46,-0.48 -1.86,-0.71c0.18,-0.35 0.39,-0.7 0.61,-1.03c1.4,-2.05 3.54,-3.56 6.02,-4.13c0.72,-0.17 1.48,-0.26 2.25,-0.26h10.8c5.37,0.94 10.32,3.13 14.47,6.26c0.16,0.39 0.3,0.8 0.4,1.22c0.18,0.66 0.29,1.34 0.32,2.05c0.01,0.15 0.01,0.31 0.01,0.47z"
                        fill="#4928f4"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                      ></path>
                      <path
                        d="M42,16v4.41l-0.22,0.68l-0.75,2.33l-0.78,2.4l-0.41,1.28l-0.38,1.19l-0.37,1.13l-0.36,1.12l-0.19,0.59l-0.25,0.78c0,0.76 -0.02,1.43 -0.07,2c-0.01,0.06 -0.02,0.12 -0.02,0.18c-0.06,0.53 -0.14,0.98 -0.27,1.36c-0.01,0.06 -0.03,0.12 -0.05,0.17c-0.26,0.72 -0.65,1.18 -1.23,1.48c-0.14,0.08 -0.3,0.14 -0.47,0.2c-0.53,0.18 -1.2,0.27 -2.02,0.32c-0.6,0.04 -1.29,0.05 -2.07,0.05h-0.69l-1.19,-0.05l-0.21,-0.01l-2.17,-0.09l-2.2,-0.09l-7.25,-0.3l-1.88,-0.08h-0.26c-0.78,-0.01 -1.45,-0.06 -2.03,-0.14c-0.84,-0.13 -1.49,-0.35 -1.98,-0.68c-0.7,-0.45 -1.11,-1.11 -1.35,-2.03c-0.06,-0.22 -0.11,-0.45 -0.14,-0.7c-0.1,-0.58 -0.15,-1.25 -0.18,-2c0,-0.15 0,-0.3 -0.01,-0.46c-0.01,-0.01 0,-0.01 0,-0.01v-0.58c-0.01,-0.29 -0.01,-0.59 -0.01,-0.9l0.05,-1.61l0.03,-1.15l0.04,-1.34v-0.19l0.07,-2.46l0.07,-2.46l0.07,-2.31l0.06,-2.27l0.02,-0.6c0,-0.31 -1.05,-0.49 -2.22,-0.64c-0.93,-0.12 -1.95,-0.23 -2.56,-0.37c0.05,-0.23 0.1,-0.46 0.16,-0.68c0.18,-0.72 0.45,-1.4 0.79,-2.05c0.18,-0.35 0.39,-0.7 0.61,-1.03c2.16,-0.95 4.41,-1.69 6.76,-2.17c2.06,-0.43 4.21,-0.66 6.43,-0.66c7.36,0 14.16,2.49 19.54,6.69c0.52,0.4 1.03,0.83 1.53,1.28c0.01,0.15 0.01,0.31 0.01,0.47z"
                        fill="#6200ea"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                      ></path>
                      <path
                        d="M42,18.37v4.54l-0.55,1.06l-1.05,2.05l-0.56,1.08l-0.51,0.99l-0.22,0.43c0,0.31 0,0.61 -0.02,0.9c0,0.43 -0.02,0.84 -0.05,1.22c-0.04,0.45 -0.1,0.86 -0.16,1.24c-0.15,0.79 -0.36,1.47 -0.66,2.03c-0.04,0.07 -0.08,0.14 -0.12,0.2c-0.11,0.18 -0.24,0.35 -0.38,0.51c-0.18,0.22 -0.38,0.41 -0.61,0.57c-0.34,0.26 -0.74,0.47 -1.2,0.63c-0.57,0.21 -1.23,0.35 -2.01,0.43c-0.51,0.05 -1.07,0.08 -1.68,0.08l-0.42,0.02l-2.08,0.12h-0.01l-2.21,0.13l-2.25,0.13l-3.1,0.18l-3.77,0.22l-0.55,0.03c-0.51,0 -0.99,-0.03 -1.45,-0.09c-0.05,-0.01 -0.09,-0.02 -0.14,-0.02c-0.68,-0.11 -1.3,-0.29 -1.86,-0.54c-0.68,-0.3 -1.27,-0.7 -1.77,-1.18c-0.44,-0.43 -0.82,-0.92 -1.13,-1.47c-0.07,-0.13 -0.14,-0.25 -0.2,-0.39c-0.3,-0.59 -0.54,-1.25 -0.72,-1.97c-0.03,-0.12 -0.06,-0.25 -0.08,-0.38c-0.06,-0.23 -0.11,-0.47 -0.14,-0.72c-0.11,-0.64 -0.17,-1.32 -0.2,-2.03v-0.01c-0.01,-0.29 -0.02,-0.57 -0.02,-0.87l-0.49,-1.17l-0.07,-0.18l-0.06,-0.15l-0.75,-1.79l-0.12,-0.29l-0.72,-1.73l-0.8,-1.93c0,0 0,0 -0.01,0l-0.81,-1.95l-0.29,-0.71v-1.59c0,-0.63 0.06,-1.25 0.17,-1.85c0.05,-0.23 0.1,-0.46 0.16,-0.68c0.85,-0.49 1.74,-0.94 2.65,-1.34c2.08,-0.93 4.31,-1.62 6.62,-2.04c1.72,-0.31 3.51,-0.48 5.32,-0.48c7.31,0 13.94,2.65 19.12,6.97c0.2,0.16 0.39,0.32 0.58,0.49c0.47,0.41 0.93,0.84 1.38,1.3z"
                        fill="#673ab7"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                      ></path>
                      <path
                        d="M42,21.35v5.14l-0.57,1.19l-1.08,2.25l-0.01,0.03c0,0.43 -0.02,0.82 -0.05,1.17c-0.1,1.15 -0.38,1.88 -0.84,2.33c-0.33,0.34 -0.74,0.53 -1.25,0.63c-0.03,0.01 -0.07,0.01 -0.1,0.02c-0.16,0.03 -0.33,0.05 -0.51,0.05c-0.62,0.06 -1.35,0.02 -2.19,-0.04c-0.09,0 -0.19,-0.01 -0.29,-0.02c-0.61,-0.04 -1.26,-0.08 -1.98,-0.11c-0.39,-0.01 -0.8,-0.02 -1.22,-0.02h-0.02l-1.01,0.08h-0.01l-2.27,0.16l-2.59,0.2l-0.38,0.03l-3.03,0.22l-1.57,0.12l-1.55,0.11c-0.27,0 -0.53,0 -0.79,-0.01c0,0 -0.01,-0.01 -0.01,0c-1.13,-0.02 -2.14,-0.09 -3.04,-0.26c-0.83,-0.14 -1.56,-0.36 -2.18,-0.69c-0.64,-0.31 -1.17,-0.75 -1.6,-1.31c-0.41,-0.55 -0.71,-1.24 -0.9,-2.07c0,-0.01 0,-0.01 0,-0.01c-0.14,-0.67 -0.22,-1.45 -0.22,-2.33l-0.15,-0.27l-0.89,-1.59l-0.13,-0.22l-0.07,-0.14l-0.93,-1.65l-0.46,-0.83l-0.58,-1.03l-1,-1.79l-0.53,-0.94v-3.68c0.88,-0.58 1.79,-1.09 2.73,-1.55c1.14,-0.58 2.32,-1.07 3.55,-1.47c1.34,-0.44 2.74,-0.79 4.17,-1.02c1.45,-0.24 2.94,-0.36 4.47,-0.36c6.8,0 13.04,2.43 17.85,6.47c0.22,0.17 0.43,0.36 0.64,0.54c0.84,0.75 1.64,1.56 2.37,2.41c0.08,0.09 0.16,0.17 0.22,0.26z"
                        fill="#8e24aa"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                      ></path>
                      <path
                        d="M42,24.71v7.23c-0.24,-0.14 -0.57,-0.31 -0.98,-0.49c-0.22,-0.11 -0.47,-0.22 -0.73,-0.32c-0.38,-0.17 -0.79,-0.33 -1.25,-0.49c-0.1,-0.04 -0.2,-0.07 -0.31,-0.1c-0.18,-0.07 -0.37,-0.13 -0.56,-0.19c-0.59,-0.18 -1.24,-0.35 -1.92,-0.5c-0.26,-0.05 -0.53,-0.1 -0.8,-0.14c-0.87,-0.15 -1.8,-0.24 -2.77,-0.25c-0.08,-0.01 -0.17,-0.01 -0.25,-0.01l-2.57,0.02l-3.5,0.02h-0.01l-7.49,0.06c-2.38,0 -3.84,0.57 -4.72,0.8c0,0 -0.01,0 -0.01,0.01c-0.93,0.24 -1.22,0.09 -1.3,-1.54c-0.02,-0.45 -0.03,-1.03 -0.03,-1.74l-0.56,-0.43l-0.98,-0.74l-0.6,-0.46l-0.12,-0.09l-1.66,-1.26l-0.25,-0.19l-0.52,-0.4l-0.96,-0.72l-1.15,-0.88v-3.4c0.1,-0.08 0.19,-0.15 0.29,-0.21c1.45,-1 3,-1.85 4.64,-2.54c1.46,-0.62 3,-1.11 4.58,-1.46c0.43,-0.09 0.87,-0.18 1.32,-0.24c1.33,-0.23 2.7,-0.34 4.09,-0.34c6.01,0 11.53,2.09 15.91,5.55c0.66,0.52 1.3,1.07 1.9,1.66c0.82,0.78 1.59,1.61 2.3,2.49c0.14,0.18 0.28,0.36 0.42,0.55c0.19,0.24 0.37,0.49 0.55,0.74z"
                        fill="#c2185b"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                      ></path>
                      <path
                        d="M42,28.72v3.28c0,0.65 -0.06,1.29 -0.18,1.91c-0.18,0.92 -0.49,1.8 -0.91,2.62c-0.22,0.05 -0.47,0.05 -0.75,0.01c-0.63,-0.11 -1.37,-0.44 -2.17,-0.87c-0.04,-0.01 -0.08,-0.03 -0.11,-0.05c-0.25,-0.13 -0.51,-0.27 -0.77,-0.43c-0.53,-0.29 -1.09,-0.61 -1.65,-0.91c-0.12,-0.06 -0.24,-0.12 -0.35,-0.18c-0.64,-0.33 -1.3,-0.63 -1.96,-0.86c0,0 0,0 -0.01,0c-0.14,-0.05 -0.29,-0.1 -0.44,-0.14c-0.57,-0.16 -1.15,-0.26 -1.71,-0.26l-1.1,-0.32l-4.87,-1.41c0,0 0,0 -0.01,0l-2.99,-0.87h-0.01l-1.3,-0.38c-3.76,0 -6.07,1.6 -7.19,0.99c-0.44,-0.23 -0.7,-0.81 -0.79,-1.95c-0.03,-0.32 -0.04,-0.68 -0.04,-1.1l-1.17,-0.57l-0.05,-0.02h-0.01l-0.84,-0.42l-0.92,-0.44l-0.07,-0.03l-0.17,-0.09l-1.96,-0.95l-1.5,-0.73v-3.43c0.17,-0.15 0.35,-0.29 0.53,-0.43c0.19,-0.15 0.38,-0.29 0.57,-0.44c0.01,0 0.01,0 0.01,0c1.18,-0.85 2.43,-1.6 3.76,-2.22c1.55,-0.74 3.2,-1.31 4.91,-1.68c0.25,-0.06 0.51,-0.12 0.77,-0.16c1.42,-0.27 2.88,-0.41 4.37,-0.41c5.27,0 10.11,1.71 14.01,4.59c1.13,0.84 2.18,1.77 3.14,2.78c0.79,0.83 1.52,1.73 2.18,2.67c0.05,0.07 0.1,0.14 0.15,0.2c0.37,0.54 0.71,1.09 1.03,1.66c0.21,0.34 0.39,0.69 0.57,1.04z"
                        fill="#d81b60"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                      ></path>
                      <path
                        d="M41.82,33.91c-0.18,0.92 -0.49,1.8 -0.91,2.62c-0.19,0.37 -0.4,0.72 -0.63,1.06c-0.14,0.21 -0.29,0.41 -0.44,0.6c-0.36,-0.14 -0.89,-0.34 -1.54,-0.56c0,0 0,0 0,-0.01c-0.49,-0.17 -1.05,-0.35 -1.65,-0.52c-0.17,-0.05 -0.34,-0.1 -0.52,-0.15c-0.71,-0.19 -1.45,-0.36 -2.17,-0.46c-0.6,-0.1 -1.19,-0.16 -1.74,-0.16l-0.46,-0.13h-0.01l-2.42,-0.7l-1.49,-0.43l-1.66,-0.48h-0.01l-0.54,-0.15l-6.53,-1.88l-1.88,-0.54l-1.4,-0.33l-2.28,-0.54l-0.28,-0.07c0,0 0,0 -0.01,0l-2.29,-0.53c0,-0.01 0,-0.01 0,-0.01l-0.41,-0.09l-0.21,-0.05l-1.67,-0.39l-0.19,-0.05l-1.42,-1.17l-1.06,-0.89v-4.08c0.37,-0.36 0.75,-0.7 1.15,-1.03c0.12,-0.11 0.25,-0.21 0.38,-0.31c0.12,-0.1 0.25,-0.2 0.38,-0.3c0.91,-0.69 1.87,-1.31 2.89,-1.84c1.3,-0.7 2.68,-1.26 4.13,-1.66c0.28,-0.09 0.56,-0.17 0.85,-0.23c1.64,-0.41 3.36,-0.62 5.14,-0.62c4.47,0 8.63,1.35 12.07,3.66c1.71,1.15 3.25,2.53 4.55,4.1c0.66,0.79 1.26,1.62 1.79,2.5c0.05,0.07 0.09,0.13 0.13,0.2c0.32,0.53 0.62,1.08 0.89,1.64c0.25,0.5 0.47,1 0.67,1.52c0.32,0.8 0.58,1.62 0.8,2.46z"
                        fill="#f50057"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                      ></path>
                      <path
                        d="M40.28,37.59c-0.14,0.21 -0.29,0.41 -0.44,0.6c-0.44,0.55 -0.92,1.05 -1.46,1.49c-0.47,0.39 -0.97,0.74 -1.5,1.04c-0.2,-0.05 -0.4,-0.11 -0.61,-0.19c-0.66,-0.23 -1.35,-0.61 -1.99,-1.01c-0.96,-0.61 -1.79,-1.27 -2.16,-1.57c-0.14,-0.12 -0.21,-0.18 -0.21,-0.18l-1.7,-0.15l-0.21,-0.02l-2.2,-0.19l-2.28,-0.2l-3.37,-0.3l-5.34,-0.47l-0.02,-0.01l-1.88,-0.91l-1.9,-0.92l-1.53,-0.74l-0.33,-0.16l-0.41,-0.2l-1.42,-0.69l-1.89,-0.91l-0.59,-0.29l-0.84,-0.26v-4.47c0.47,-0.56 0.97,-1.09 1.5,-1.6c0.34,-0.32 0.7,-0.64 1.07,-0.94c0.06,-0.05 0.12,-0.1 0.18,-0.14c0.04,-0.05 0.09,-0.08 0.13,-0.1c0.59,-0.48 1.21,-0.91 1.85,-1.3c0.74,-0.47 1.52,-0.89 2.33,-1.24c0.87,-0.39 1.78,-0.72 2.72,-0.97c1.63,-0.46 3.36,-0.7 5.14,-0.7c4.08,0 7.85,1.24 10.96,3.37c1.99,1.36 3.71,3.08 5.07,5.07c0.45,0.64 0.85,1.32 1.22,2.02c0.13,0.26 0.26,0.52 0.37,0.78c0.12,0.25 0.23,0.5 0.34,0.75c0.21,0.52 0.4,1.04 0.57,1.58c0.32,1 0.56,2.02 0.71,3.08c0.05,0.35 0.09,0.7 0.12,1.05z"
                        fill="#ff1744"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                      ></path>
                      <path
                        d="M38.39,39.42c0,0.08 0,0.17 -0.01,0.26c-0.47,0.39 -0.97,0.74 -1.5,1.04c-0.22,0.12 -0.44,0.24 -0.67,0.34c-0.23,0.11 -0.46,0.21 -0.7,0.3c-0.34,-0.18 -0.8,-0.4 -1.29,-0.61c-0.69,-0.31 -1.44,-0.59 -2.02,-0.68c-0.14,-0.03 -0.27,-0.04 -0.39,-0.04l-1.64,-0.21h-0.02l-2.04,-0.27l-2.06,-0.27l-0.96,-0.12l-7.56,-0.98c-0.49,0 -1.01,-0.03 -1.55,-0.1c-0.66,-0.06 -1.35,-0.16 -2.04,-0.3c-0.68,-0.12 -1.37,-0.28 -2.03,-0.45c-0.69,-0.16 -1.37,-0.35 -2,-0.53c-0.73,-0.22 -1.41,-0.43 -1.98,-0.62c-0.47,-0.15 -0.87,-0.29 -1.18,-0.4c-0.18,-0.43 -0.33,-0.88 -0.44,-1.34c-0.21,-0.78 -0.31,-1.6 -0.31,-2.44v-1.67c0.32,-0.53 0.67,-1.05 1.06,-1.54c0.71,-0.94 1.52,-1.8 2.4,-2.56c0.03,-0.04 0.07,-0.07 0.1,-0.09l0.01,-0.01c0.31,-0.28 0.63,-0.53 0.97,-0.77c0.04,-0.04 0.08,-0.07 0.12,-0.1c0.16,-0.12 0.33,-0.24 0.51,-0.35c1.43,-0.97 3.01,-1.73 4.7,-2.24c1.6,-0.48 3.29,-0.73 5.05,-0.73c3.49,0 6.75,1.03 9.47,2.79c2.01,1.29 3.74,2.99 5.06,4.98c0.16,0.23 0.31,0.46 0.46,0.7c0.69,1.17 1.26,2.43 1.68,3.75c0.05,0.15 0.09,0.3 0.13,0.46c0.08,0.27 0.15,0.55 0.21,0.83c0.02,0.07 0.04,0.14 0.06,0.22c0.14,0.63 0.24,1.29 0.31,1.95c0,0.01 0,0.01 0,0.01c0.06,0.59 0.09,1.19 0.09,1.79z"
                        fill="#ff5722"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                      ></path>
                      <path
                        d="M36.33,39.42c0,0.35 -0.02,0.73 -0.06,1.11c-0.02,0.18 -0.04,0.36 -0.06,0.53c-0.23,0.11 -0.46,0.21 -0.7,0.3c-0.45,0.17 -0.91,0.31 -1.38,0.41c-0.32,0.07 -0.65,0.13 -0.98,0.16h-0.01c-0.31,-0.19 -0.67,-0.42 -1.04,-0.68c-0.67,-0.47 -1.37,-1 -1.93,-1.43c-0.01,-0.01 -0.01,-0.01 -0.02,-0.02c-0.59,-0.45 -1.01,-0.79 -1.01,-0.79l-1.06,0.04l-2.04,0.07l-0.95,0.04l-3.82,0.14l-3.23,0.12c-0.21,0.01 -0.46,0.01 -0.77,0h-0.01c-0.42,-0.01 -0.92,-0.04 -1.47,-0.09c-0.64,-0.05 -1.34,-0.11 -2.05,-0.18c-0.69,-0.08 -1.39,-0.16 -2.06,-0.24c-0.74,-0.08 -1.44,-0.17 -2.04,-0.25c-0.47,-0.06 -0.88,-0.11 -1.21,-0.15c-0.28,-0.32 -0.53,-0.65 -0.77,-1.01c-0.36,-0.54 -0.67,-1.11 -0.91,-1.72c-0.18,-0.43 -0.33,-0.88 -0.44,-1.34c0.29,-0.89 0.67,-1.73 1.12,-2.54c0.36,-0.66 0.78,-1.29 1.24,-1.89c0.45,-0.59 0.94,-1.14 1.47,-1.64v-0.01c0.15,-0.15 0.3,-0.29 0.45,-0.42c0.28,-0.26 0.57,-0.5 0.87,-0.73h0.01c0.01,-0.02 0.02,-0.02 0.03,-0.03c0.24,-0.19 0.49,-0.36 0.74,-0.53c1.48,-1.01 3.15,-1.76 4.95,-2.2c1.19,-0.29 2.44,-0.45 3.73,-0.45c2.54,0 4.94,0.61 7.05,1.71h0.01c1.81,0.93 3.41,2.21 4.7,3.75c0.71,0.82 1.32,1.72 1.82,2.67c0.35,0.64 0.65,1.31 0.9,1.99c0.02,0.06 0.04,0.11 0.06,0.16c0.17,0.5 0.32,1.02 0.45,1.54c0.09,0.37 0.16,0.75 0.22,1.13c0.02,0.12 0.04,0.23 0.05,0.35c0.1,0.69 0.15,1.4 0.15,2.12z"
                        fill="#ff6f00"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                      ></path>
                      <path
                        d="M34.28,39.42v0.1c0,0.34 -0.03,0.77 -0.06,1.23c-0.03,0.34 -0.06,0.69 -0.09,1.02c-0.32,0.07 -0.65,0.13 -0.98,0.16h-0.01c-0.38,0.05 -0.75,0.07 -1.14,0.07h-1.75l-0.38,-0.11l-1.97,-0.6l-2,-0.6l-4.63,-1.39l-2,-0.6c0,0 -0.83,0.33 -2,0.72h-0.01c-0.45,0.15 -0.94,0.31 -1.46,0.47c-0.65,0.19 -1.34,0.38 -2.02,0.53c-0.7,0.16 -1.39,0.28 -2.01,0.33c-0.19,0.02 -0.38,0.03 -0.55,0.03c-0.56,-0.31 -1.1,-0.68 -1.59,-1.09c-0.43,-0.36 -0.83,-0.75 -1.2,-1.18c-0.28,-0.32 -0.53,-0.65 -0.77,-1.01c0.07,-0.45 0.15,-0.89 0.27,-1.32c0.3,-1.19 0.77,-2.33 1.39,-3.37c0.34,-0.59 0.72,-1.16 1.16,-1.69c0.01,-0.03 0.04,-0.06 0.07,-0.08c-0.01,-0.01 0,-0.01 0,-0.01c0.13,-0.17 0.27,-0.33 0.41,-0.48c0,-0.01 0,-0.01 0,-0.01c0.41,-0.44 0.83,-0.86 1.29,-1.25c0.16,-0.13 0.31,-0.26 0.48,-0.39c0.03,-0.03 0.06,-0.05 0.1,-0.08c2.25,-1.72 5.06,-2.76 8.09,-2.76c3.44,0 6.57,1.29 8.94,3.41c1.14,1.03 2.11,2.26 2.84,3.63c0.06,0.1 0.12,0.21 0.17,0.32c0.09,0.18 0.18,0.37 0.26,0.57c0.33,0.72 0.59,1.48 0.77,2.26c0.02,0.08 0.04,0.16 0.06,0.24c0.08,0.37 0.15,0.75 0.2,1.13c0.08,0.59 0.12,1.19 0.12,1.8z"
                        fill="#ff9800"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                      ></path>
                      <path
                        d="M32.22,39.42c0,0.2 -0.01,0.42 -0.02,0.65c-0.02,0.37 -0.05,0.77 -0.1,1.18c-0.02,0.25 -0.06,0.5 -0.1,0.75h-5.48l-1.06,-0.17l-4.14,-0.66l-0.59,-0.09l-1.35,-0.22c-0.59,0 -1.87,0.26 -3.22,0.51c-0.71,0.13 -1.43,0.27 -2.08,0.36c-0.08,0.01 -0.16,0.02 -0.23,0.03h-0.01c-0.7,-0.15 -1.38,-0.38 -2.02,-0.68c-0.2,-0.09 -0.4,-0.19 -0.6,-0.3c-0.56,-0.31 -1.1,-0.68 -1.59,-1.09c-0.01,-0.12 -0.02,-0.22 -0.02,-0.27c0,-0.26 0.01,-0.51 0.03,-0.76c0.04,-0.64 0.13,-1.26 0.27,-1.86c0.22,-0.91 0.54,-1.79 0.97,-2.6c0.08,-0.17 0.17,-0.34 0.27,-0.5c0.04,-0.08 0.09,-0.15 0.13,-0.23c0.18,-0.29 0.38,-0.57 0.58,-0.85c0.42,-0.55 0.89,-1.07 1.39,-1.54c0.01,0 0.01,0 0.01,0c0.04,-0.04 0.08,-0.08 0.12,-0.11c0.05,-0.04 0.09,-0.09 0.14,-0.12c0.2,-0.18 0.4,-0.34 0.61,-0.49c0,-0.01 0.01,-0.01 0.01,-0.01c1.89,-1.41 4.23,-2.24 6.78,-2.24c1.98,0 3.82,0.5 5.43,1.38h0.01c1.38,0.76 2.58,1.79 3.53,3.03c0.37,0.48 0.7,0.99 0.98,1.53h0.01c0.05,0.1 0.1,0.2 0.15,0.3c0.3,0.59 0.54,1.21 0.72,1.85h0.01c0.01,0.05 0.03,0.1 0.04,0.15c0.12,0.43 0.22,0.87 0.29,1.32c0.01,0.09 0.02,0.19 0.03,0.28c0.07,0.48 0.1,0.97 0.1,1.47z"
                        fill="#ffc107"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                      ></path>
                      <path
                        d="M30.17,39.31c0,0.16 0,0.33 -0.02,0.49v0.01c0,0.01 0,0.01 0,0.01c-0.02,0.72 -0.12,1.43 -0.28,2.07c0,0.04 -0.01,0.07 -0.03,0.11h-4.67l-3.85,-0.83l-0.51,-0.11l-0.08,0.02l-4.27,0.88l-0.19,0.04h-0.27c-0.64,0 -1.27,-0.06 -1.88,-0.18c-0.09,-0.02 -0.18,-0.04 -0.27,-0.06h-0.01c-0.7,-0.15 -1.38,-0.38 -2.02,-0.68c-0.02,-0.11 -0.04,-0.22 -0.05,-0.33c-0.07,-0.43 -0.1,-0.88 -0.1,-1.33c0,-0.17 0,-0.34 0.01,-0.51c0.03,-0.54 0.11,-1.07 0.23,-1.58c0.08,-0.38 0.19,-0.75 0.32,-1.1c0.11,-0.31 0.24,-0.61 0.38,-0.9c0.12,-0.25 0.26,-0.49 0.4,-0.73c0.14,-0.23 0.29,-0.45 0.45,-0.67c0.4,-0.55 0.87,-1.06 1.39,-1.51c0.3,-0.26 0.63,-0.51 0.97,-0.73c1.46,-0.96 3.21,-1.52 5.1,-1.52c0.37,0 0.73,0.02 1.08,0.07h0.02c1.07,0.12 2.07,0.42 2.99,0.87c0.01,0 0.01,0 0.01,0c1.45,0.71 2.68,1.78 3.58,3.1c0.15,0.22 0.3,0.46 0.43,0.7c0.11,0.19 0.21,0.39 0.3,0.59c0.14,0.31 0.27,0.64 0.38,0.97h0.01c0.11,0.37 0.21,0.74 0.28,1.13v0.01c0.11,0.55 0.17,1.12 0.17,1.7z"
                        fill="#ffd54f"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                      ></path>
                      <path
                        d="M28.11,39.52v0.03c0,0.59 -0.07,1.17 -0.21,1.74c-0.05,0.24 -0.12,0.48 -0.21,0.71h-4.48l-2.29,-0.63l-2.29,0.63h-2.63c-0.64,0 -1.27,-0.06 -1.88,-0.18c-0.02,-0.03 -0.03,-0.06 -0.04,-0.09c-0.14,-0.43 -0.25,-0.86 -0.3,-1.31c-0.04,-0.29 -0.06,-0.59 -0.06,-0.9c0,-0.12 0,-0.25 0.02,-0.37c0.01,-0.47 0.08,-0.93 0.2,-1.37c0.06,-0.3 0.15,-0.59 0.27,-0.87c0.04,-0.14 0.1,-0.27 0.17,-0.4c0.15,-0.34 0.33,-0.67 0.53,-0.99c0.22,-0.32 0.46,-0.62 0.73,-0.9c0.32,-0.36 0.68,-0.69 1.09,-0.96c0.7,-0.51 1.5,-0.89 2.37,-1.1c0.58,-0.16 1.19,-0.24 1.82,-0.24c2,0 3.79,0.8 5.09,2.09c0.05,0.05 0.11,0.11 0.16,0.18h0.01c0.14,0.15 0.27,0.3 0.4,0.47c0.37,0.47 0.68,0.98 0.92,1.54c0.12,0.26 0.22,0.53 0.3,0.81c0.01,0.04 0.02,0.07 0.03,0.11c0.14,0.49 0.23,1 0.25,1.53c0.02,0.15 0.03,0.31 0.03,0.47z"
                        fill="#ffe082"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                      ></path>
                      <path
                        d="M26.06,39.52c0,0.41 -0.05,0.8 -0.16,1.17c-0.1,0.4 -0.25,0.78 -0.44,1.14c-0.03,0.06 -0.1,0.17 -0.1,0.17h-8.88c-0.01,-0.01 -0.02,-0.03 -0.02,-0.04c-0.12,-0.19 -0.22,-0.38 -0.3,-0.59c-0.2,-0.46 -0.32,-0.96 -0.36,-1.48c-0.02,-0.12 -0.02,-0.25 -0.02,-0.37c0,-0.06 0,-0.13 0.01,-0.19c0.01,-0.44 0.07,-0.86 0.19,-1.25c0.1,-0.36 0.23,-0.69 0.4,-1.01c0,0 0.01,-0.01 0.01,-0.02c0.12,-0.21 0.25,-0.42 0.4,-0.62c0.49,-0.66 1.14,-1.2 1.89,-1.55c0.01,0 0.01,0 0.01,0c0.24,-0.12 0.49,-0.22 0.75,-0.29c0,0 0,0 0.01,0c0.46,-0.14 0.96,-0.21 1.47,-0.21c0.59,0 1.16,0.09 1.68,0.28c0.19,0.05 0.37,0.13 0.55,0.22c0,0 0,0 0.01,0c0.86,0.41 1.59,1.05 2.09,1.85c0.1,0.15 0.19,0.31 0.27,0.48c0.04,0.07 0.08,0.15 0.11,0.22c0.23,0.52 0.37,1.09 0.41,1.69c0.01,0.05 0.01,0.1 0.01,0.16c0.01,0.08 0.01,0.16 0.01,0.24z"
                        fill="#ffecb3"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                      ></path>
                      <g>
                        <path
                          d="M30,11h-12c-3.9,0 -7,3.1 -7,7v12c0,3.9 3.1,7 7,7h12c3.9,0 7,-3.1 7,-7v-12c0,-3.9 -3.1,-7 -7,-7z"
                          fill="none"
                          stroke="#ffffff"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <circle
                          cx="31"
                          cy="16"
                          r="1"
                          fill="#ffffff"
                          stroke="none"
                          strokeWidth="1"
                          strokeLinecap="butt"
                          strokeLinejoin="miter"
                        ></circle>
                      </g>
                      <g
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <circle cx="24" cy="24" r="6"></circle>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </a>
          </div>
          <div className="ContactItemIcon">
            <a
              href="https://wa.me/919421463713"
              target="_blank"
              rel="noreferrer"
            >
              <div className="contactSocial">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="20,28,206,206"
                  style={{ fill: "#000000" }}
                >
                  <g
                    fillOpacity="0"
                    fill="#dddddd"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="1"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="10"
                    strokeDasharray=""
                    strokeDashoffset="0"
                    fontFamily="none"
                    fontWeight="none"
                    fontSize="none"
                    textAnchor="none"
                    style={{ mixBlendMode: "normal" }}
                  >
                    <path d="M0,256v-256h256v256z" id="bgRectangle"></path>
                  </g>
                  <g
                    fill="none"
                    fillRule="none"
                    stroke="none"
                    strokeWidth="1"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="10"
                    strokeDasharray=""
                    strokeDashoffset="0"
                    fontFamily="none"
                    fontWeight="none"
                    fontSize="none"
                    textAnchor="none"
                    style={{ mixBlendMode: "normal" }}
                  >
                    <g transform="scale(5.33333,5.33333)">
                      <path
                        d="M4.868,43.303l2.694,-9.835c-1.662,-2.878 -2.536,-6.144 -2.535,-9.489c0.005,-10.465 8.521,-18.979 18.987,-18.979c5.079,0.002 9.845,1.979 13.43,5.566c3.584,3.588 5.558,8.356 5.556,13.428c-0.004,10.465 -8.522,18.98 -18.986,18.98c-0.001,0 0,0 0,0h-0.008c-3.177,-0.001 -6.3,-0.798 -9.073,-2.311z"
                        fill="#ffffff"
                        fillRule="nonzero"
                      ></path>
                      <path
                        d="M4.868,43.803c-0.132,0 -0.26,-0.052 -0.355,-0.148c-0.125,-0.127 -0.174,-0.312 -0.127,-0.483l2.639,-9.636c-1.636,-2.906 -2.499,-6.206 -2.497,-9.556c0.004,-10.742 8.745,-19.48 19.486,-19.48c5.21,0.002 10.105,2.031 13.784,5.713c3.679,3.683 5.704,8.577 5.702,13.781c-0.004,10.741 -8.746,19.48 -19.486,19.48c-3.189,-0.001 -6.344,-0.788 -9.144,-2.277l-9.875,2.589c-0.042,0.012 -0.084,0.017 -0.127,0.017z"
                        fill="#ffffff"
                        fillRule="nonzero"
                      ></path>
                      <path
                        d="M24.014,5c5.079,0.002 9.845,1.979 13.43,5.566c3.584,3.588 5.558,8.356 5.556,13.428c-0.004,10.465 -8.522,18.98 -18.986,18.98h-0.008c-3.177,-0.001 -6.3,-0.798 -9.073,-2.311l-10.065,2.64l2.694,-9.835c-1.662,-2.878 -2.536,-6.144 -2.535,-9.489c0.005,-10.465 8.521,-18.979 18.987,-18.979M24.014,42.974v0v0M24.014,42.974v0v0M24.014,4v0c-11.016,0 -19.982,8.962 -19.987,19.979c-0.001,3.367 0.849,6.685 2.461,9.622l-2.585,9.439c-0.094,0.345 0.002,0.713 0.254,0.967c0.19,0.192 0.447,0.297 0.711,0.297c0.085,0 0.17,-0.011 0.254,-0.033l9.687,-2.54c2.828,1.468 5.998,2.243 9.197,2.244c11.024,0 19.99,-8.963 19.995,-19.98c0.002,-5.339 -2.075,-10.359 -5.848,-14.135c-3.775,-3.777 -8.796,-5.858 -14.139,-5.86z"
                        fill="#cfd8dc"
                        fillRule="nonzero"
                      ></path>
                      <path
                        d="M35.176,12.832c-2.98,-2.982 -6.941,-4.625 -11.157,-4.626c-8.704,0 -15.783,7.076 -15.787,15.774c-0.001,2.981 0.833,5.883 2.413,8.396l0.376,0.597l-1.595,5.821l5.973,-1.566l0.577,0.342c2.422,1.438 5.2,2.198 8.032,2.199h0.006c8.698,0 15.777,-7.077 15.78,-15.776c0.001,-4.215 -1.638,-8.179 -4.618,-11.161z"
                        fill="#40c351"
                        fillRule="nonzero"
                      ></path>
                      <path
                        d="M19.268,16.045c-0.355,-0.79 -0.729,-0.806 -1.068,-0.82c-0.277,-0.012 -0.593,-0.011 -0.909,-0.011c-0.316,0 -0.83,0.119 -1.265,0.594c-0.435,0.475 -1.661,1.622 -1.661,3.956c0,2.334 1.7,4.59 1.937,4.906c0.237,0.316 3.282,5.259 8.104,7.161c4.007,1.58 4.823,1.266 5.693,1.187c0.87,-0.079 2.807,-1.147 3.202,-2.255c0.395,-1.108 0.395,-2.057 0.277,-2.255c-0.119,-0.198 -0.435,-0.316 -0.909,-0.554c-0.474,-0.238 -2.807,-1.385 -3.242,-1.543c-0.435,-0.158 -0.751,-0.237 -1.068,0.238c-0.316,0.474 -1.225,1.543 -1.502,1.859c-0.277,0.317 -0.554,0.357 -1.028,0.119c-0.474,-0.238 -2.002,-0.738 -3.815,-2.354c-1.41,-1.257 -2.362,-2.81 -2.639,-3.285c-0.277,-0.474 -0.03,-0.731 0.208,-0.968c0.213,-0.213 0.474,-0.554 0.712,-0.831c0.237,-0.277 0.316,-0.475 0.474,-0.791c0.158,-0.317 0.079,-0.594 -0.04,-0.831c-0.117,-0.238 -1.039,-2.584 -1.461,-3.522z"
                        fill="#ffffff"
                        fillRule="evenodd"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
            </a>
          </div>
          <div className="ContactItemIcon">
            <a
              href="https://www.youtube.com/@siddhantirrigationcentre6291"
              target="_blank"
              rel="noreferrer"
            >
              <div className="contactSocial">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="20,20,216,216"
                  style={{fill:"#000000"}}
                >
                  <g
                    fillOpacity="0"
                    fill="#dddddd"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="1"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="10"
                    strokeDasharray=""
                    strokeDashoffset="0"
                    fontFamily="none"
                    fontWeight="none"
                    fontSize="none"
                    textAnchor="none"
                    style={{mixBlendMode: "normal"}}
                  >
                    <path d="M0,256v-256h256v256z" id="bgRectangle"></path>
                  </g>
                  <g
                    fill="none"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="1"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="10"
                    strokeDasharray=""
                    strokeDashoffset="0"
                    fontFamily="none"
                    fontWeight="none"
                    fontSize="none"
                    textAnchor="none"
                    style={{mixBlendMode: "normal"}}
                  >
                    <g transform="scale(5.33333,5.33333)">
                      <path
                        d="M43.2,33.9c-0.4,2.1 -2.1,3.7 -4.2,4c-3.3,0.5 -8.8,1.1 -15,1.1c-6.1,0 -11.6,-0.6 -15,-1.1c-2.1,-0.3 -3.8,-1.9 -4.2,-4c-0.4,-2.3 -0.8,-5.7 -0.8,-9.9c0,-4.2 0.4,-7.6 0.8,-9.9c0.4,-2.1 2.1,-3.7 4.2,-4c3.3,-0.5 8.8,-1.1 15,-1.1c6.2,0 11.6,0.6 15,1.1c2.1,0.3 3.8,1.9 4.2,4c0.4,2.3 0.9,5.7 0.9,9.9c-0.1,4.2 -0.5,7.6 -0.9,9.9z"
                        fill="#ff3d00"
                      ></path>
                      <path d="M20,31v-14l12,7z" fill="#ffffff"></path>
                    </g>
                  </g>
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="ContactCol">
        <div className="ContactItemHead">Contact us</div>
        <div className="ContactItemIcon">
          <a target="_blank" rel="noreferrer" href="tel:+919421463713">
            <span className="animatedIcon">
              <IoMdArrowDropright />
            </span>
            Call us at : 9421463713
          </a>
        </div>
        <div className="ContactItemIcon">
          <a
            target="_blank"
            rel="noreferrer"
            href="mailto:siddhantirrigation@yahoo.com"
          >
            <span className="animatedIcon">
              <IoMdArrowDropright />
            </span>
            siddhantirrigation@yahoo.com
          </a>
        </div>
        <div className="ContactItem">
            <span onClick={handlePopState}>
              <span className="animatedIcon">
                <IoMdArrowDropright />
              </span>
              info
            </span>{" "}
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
