import React from "react";
import "../CSS/Home.css";
import ContactUs from "./ContactUs";
import Products from "./Products";
import CarouselTop from "./CarouselTop";
import NameCard from "./NameCard";

function Home() {

  return (
    <>
      <div id="Home">
        <CarouselTop />
        <NameCard/>
      </div>
      <section id="Products">
        <Products />
      </section>
      <section id="ContactUs">
        <ContactUs />
      </section>
    </>
  );
}

export default Home;
