import React,{useEffect,useState} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

function NameCard() {
    const [textAnimationClass,setTextAnimationClass]=useState("textAnimatefrom");
    const jain = require('../assets/jain.png');
    const prayag = require('../assets/prayag.png');
    const supreme = require('../assets/supreme.png');
    const psg = require('../assets/psg.png');
    const side_leaf = require('../assets/side_leaf.png');
    const palm_leaf = require('../assets/palm_leaf.png');
    const florida_leaf = require('../assets/florida_leaf.png');
    const sona2 = require('../assets/sona2.webp');
    // const HP_Solvent = require('../assets/Hp Solvents.png');
    // const Jalmoti = require('../assets/Jalmoti2.jpg');
    // const Kana = require('../assets/Kana.png');
    // const Plumtek = require('../assets/Plumtek.png');

    const Plumtek = require('../assets/plumtek4.jpg');
    const Jalmoti = require('../assets/jalmoti4.jpg');
    // const HP_Solvent = require('../assets/hp4.jpg');
    const HP_Solvent = require('../assets/hp41.jpg')
    const Kana = require('../assets/kana4.jpg');

    const [div1,setDiv1]=useState("CompanyCardsDiv"); 
    const [div2,setDiv2]=useState("CompanyCardsDiv divInvisibleW"); 
    const [btnClass1,setBtnClass1] = useState("button-30 button-30-click");
    const [btnClass2,setBtnClass2] = useState("button-30");

    useEffect(() => {
        const timer = setTimeout(() => { 
        setTextAnimationClass("textAnimateto");
        AOS.init({duration:1000});
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    const handleSaleBtn=(page)=>{
      console.log(page);
        if(page===1){
          setDiv1("CompanyCardsDiv divVisible");
          setDiv2("CompanyCardsDiv divInvisibleW");
          setBtnClass1("button-30 button-30-click");
          setBtnClass2("button-30");
        }
        else{
          setDiv2("CompanyCardsDiv divVisible");
          setDiv1("CompanyCardsDiv divInvisibleR");
          setBtnClass1("button-30");
          setBtnClass2("button-30 button-30-click");
        }
    }
  return (
    <div  className="welcomeDiv">
            <div className="outerSideLeaf">
              <div className="sideLeaf">
                <img alt="sideleaf" src={side_leaf}/>
              </div>
            </div>
            <div className="outerSideLeaf">
              <div className="sidePalmLeaf">
                <img alt="sideleaf" style={{height:"550px"}} src={palm_leaf}/>
              </div>
            </div>
            <div className="outerSideLeaf">
              <div className="sideFloridaLeaf">
                <img alt="sideleaf" style={{height:"100px"}} src={florida_leaf}/>
              </div>
            </div>
          <div className="textDivOuter">
            <div className="hideDiv">
              <span className={textAnimationClass}>Hello there,</span>
            </div>
            <div className="hideDiv">
              <span className={textAnimationClass}>Welcome to</span>
            </div>
            <div className="hideDiv">
              <span className={`${textAnimationClass} shopName`}>SIDDHANT IRRIGATION CENTER</span>
            </div>
            <div className="hideDiv">
              <span className={`${textAnimationClass} shopName`}>and DISTRIBUTORS.</span>
            </div>
            <div className="hideDiv weOfferText">
              <span className={textAnimationClass}>We offer products from</span>
            </div>
          </div>

          <div className="companyCardsOuterDiv">
            <div className='saleBtnContainer'>
              <div className={btnClass1} onClick={()=>{handleSaleBtn(1)}}>
                Retail
              </div>
              <div className={btnClass2} onClick={()=>{handleSaleBtn(2)}}>
                WholeSale
              </div>
            </div>
          <div className={div1}>  
            <div className="companyCard" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
              <img className="companyCardLogo" alt="jain" src={jain}/>
            </div>
            <div className="companyCard" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
              <img className="companyCardLogo" alt="sumpreme" src={supreme}/>
            </div>
            <div className="companyCard" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
              <img className="companyCardLogo" alt="prayag" src={prayag}/>
            </div>
            <div className="companyCard" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
              <img className="companyCardLogoPSG" alt="psg" src={psg}/>
            </div>
            <div className="companyCard" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
              <img className="companyCardLogoPSG" alt="sona" src={sona2}/>
            </div>
          </div>

          <div className={div2}>  
            <div className="companyCard companyCardBorder" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
              <img className="companyCardLogo" alt="plumtek" src={Plumtek}/>
              {/* <div className='companyCardName'>
                Plumtek Taps
              </div> */}
            </div>
            <div className="companyCard companyCardBorder" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
              <img className="companyCardLogo" alt="kana" src={Kana}/>
              {/* <div className='companyCardName'>
                Kana Valves
              </div> */}
            </div>
            <div className="companyCard companyCardBorder" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
              <img className="companyCardLogo" alt="hp" src={HP_Solvent}/>
              {/* <div className='companyCardName'>
                HP Solvents
              </div> */}
            </div>
            <div className="companyCard companyCardBorder" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
              <img className="companyCardLogo" alt="psg" src={psg}/>
              {/* <div className='companyCardName'>
                Jalmoti PVC pipes
              </div> */}
            </div>
            <div className="companyCard companyCardBorder" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
              <img className="companyCardLogo" alt="supreme" src={supreme}/>
              {/* <div className='companyCardName'>
                Supreme Garden Pipes
              </div> */}
            </div>
            <div className="companyCard companyCardBorder" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
              <img className="companyCardLogo" alt="jalmoti" src={Jalmoti}/>
              {/* <div className='companyCardName'>
                Jalmoti PVC pipes
              </div> */}
            </div>
            

          </div>
          </div>
    </div>
  )
}

export default NameCard