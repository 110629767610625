import React from 'react'
// import { useState,useEffect } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import '../CSS/CarouselTop.css'

function CarouselTop() {
  // const [welcomeBanner,setWelcomeBanner]=useState(true);
  const Drip = require('../assets/DripN1.webp');
  const pump = require('../assets/PumpN1.jpg');
  const jalgaon01 = require('../assets/jalgaon01.jpg');
  const jalgaon02 = require('../assets/jalgaon02.jpg');
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setWelcomeBanner(false);
  //   }, 3000);
  //   return () => clearTimeout(timer);
  // }, []);

  // const wlcm=()=>{
  //   return(
  //     welcomeBanner?
  //     <>
  //     <img
  //       className="d-block w-100 CarouselImg"
  //       src={Drip}
  //       alt="First slide"
  //     />
  //     </>:
  //     <img
  //       className="d-block w-100 CarouselImg"
  //       src={Drip2}
  //       alt="First slide"
  //     />
  //   )
  // }
  
  return (
    <>
    <div className='CarouselTopST'>
      <Carousel>
      {/* <Carousel.Item interval={2000}>
        {wlcm()}
      </Carousel.Item> */}
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100 CarouselImg"
          src={Drip}
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100 CarouselImg"
          src={jalgaon01}
          alt="Second slide"
        />
      </Carousel.Item>


      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100 CarouselImg"
          src={jalgaon02}
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100 CarouselImg"
          src={pump}
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
    </div>
    </>
  )
}

export default CarouselTop